import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "../libs/i18n/index"; // import VueI18n instance
// import creditnotes from "../views/creditnotes.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Home.vue"),
      meta: {
        pageTitle: "Dashboard",
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/all-invoices",
      name: "all-invoices",
      component: () => import("@/views/all-invoices.vue"),
      meta: {
        pageTitle: "All Invoices",
        breadcrumb: [
          {
            text: "All Invoices",
            active: true,
          },
        ],
      },
    },
    // {
    //   path: "/credit-notes",
    //   name: "credit-notes",
    //   component: () => import("@/views/credit-notes.vue"),
    //   meta: {
    //     pageTitle: "Credit Notes",
    //     breadcrumb: [
    //       {
    //         text: "Credit Notes",
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: "/paid-invoices",
    //   name: "paid-invoices",
    //   component: () => import("@/views/paid-invoices.vue"),
    //   meta: {
    //     pageTitle: "Paid Invoices",
    //     breadcrumb: [
    //       {
    //         text: "Paid Invoices",
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: "/credit-note",
      name: "credit-note",
      component: () => import("@/views/credit-notes.vue"),
      meta: {
        pageTitle: "Credit Notes",
        breadcrumb: [
          {
            text: "Credit Notes",
            active: true,
          },
        ],
      },
    },
    {
      path: "/unpaid-invoices",
      name: "unpaid-invoices",
      component: () => import("@/views/unpaid-invoices.vue"),
      meta: {
        pageTitle: "Unpaid Invoices",
        breadcrumb: [
          {
            text: "Unpaid Invoices",
            active: true,
          },
        ],
      },
    },
    {
      path: "/overdue-invoices",
      name: "overdue-invoices",
      component: () => import("@/views/overdue-invoices.vue"),
      meta: {
        pageTitle: "Overdue Invoices",
        breadcrumb: [
          {
            text: "Overdue Invoices",
            active: true,
          },
        ],
      },
    },
    // {
    //   path: "/settings",
    //   name: "settings",
    //   component: () => import("@/views/settings.vue"),
    //   meta: {
    //     pageTitle: "Setting",
    //     breadcrumb: [
    //       {
    //         text: "Setting",
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: "/account-statements",
      name: "account-statements",
      component: () => import("@/views/ledger.vue"),
      meta: {
        pageTitle: "Account Statements",
        breadcrumb: [
          {
            text: "Account Statements",
            active: true,
          },
        ],
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
